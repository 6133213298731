import React, { Component } from 'react';
import { Link } from '@reach/router';
import FilterList from '../containers/FilterList';
import Moment from 'react-moment';
import $ from 'jquery';

function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

class VoiceTable extends Component {
  constructor(props) {
        super(props);
        
         this.state = { 
            voices2Display: this.props.voices,
            filteredVoices: [],
            myArtfields: [],
            myKeywords: [],
            isMobile_w700: false
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
      this.throttledHandleWindowResize = this.throttledHandleWindowResize.bind(this);
    } 
    
  componentDidMount() {     
      
      this.setState({isMobile_w700: window.innerWidth < 700});
      if(window.innerWidth < 700) {
          $('.tableContainer').addClass('mobile_w700'); 
       } 
      else{
           $('.tableContainer').removeClass('mobile_w700');
      }   
       window.addEventListener('resize', this.throttledHandleWindowResize);
      
    // populate filteredVoices =[]
    if(this.state.filteredVoices.length == 0)
    {
        var myNewArray = []; 
        for(var i in this.props.voices)
        {
            myNewArray[i] =  this.props.voices[i].nome.toLowerCase() + ' ' + this.props.voices[i].cognome.toLowerCase()+';';
            for(var y in this.props.voices[i].interviews){
                myNewArray[i] += this.props.voices[i].interviews[y].title.toLowerCase()+';';
            }
        }
        if(myNewArray.length > 0)
            this.setState({filteredVoices: myNewArray});
    }
}
componentDidUpdate(prevState) {
  // Typical usage (don't forget to compare props):
}

handleSearch(){
    var myArtfield = $('.dropdown.artfields option:selected').val();
    var myKeywords = $('.dropdown.keywords option:selected').val();
    var searchValue = $('#searchValue').val().trim().toLowerCase();
   
    var myInterviews = this.props.voices;
    if(myArtfield!='*'|| myKeywords!='*' || searchValue!='')
    {
         myInterviews = [];
         if(searchValue=='')// ricerca solo in picklist
         {
             if(myArtfield!='*' && myKeywords!='*')
             {
                 var firstInterviews = [];
                 for(var y in this.props.voices)
                {
                    if(this.props.voices[y].interviews[0]!=undefined)
                  for(var myKey2 in  this.props.voices[y].interviews[0].artfields)
                      if(this.props.voices[y].interviews[0].artfields[myKey2] === myArtfield)
                        firstInterviews.push(this.props.voices[y]);
                 
                 for(var x in firstInterviews)
                  for(var myKey in  firstInterviews[x].interviews[0].keywords)
                      if(firstInterviews[x].interviews[0].keywords[myKey] === myKeywords)
                        myInterviews.push(firstInterviews[x]);
                }
             }
             else if(myArtfield!='*'){
                 for(var x in this.props.voices)
                {
                    if(this.props.voices[x].interviews[0]!=undefined)
                  for(var myKey in  this.props.voices[x].interviews[0].artfields)
                      if(this.props.voices[x].interviews[0].artfields[myKey] === myArtfield)
                        myInterviews.push(this.props.voices[x]);
                }
             }
            else if(myKeywords!='*')
            {
                for(var x in this.props.voices)
                    {
                if(this.props.voices[x].interviews[0]!=undefined)
                  for(var myKey in  this.props.voices[x].interviews[0].keywords)
                      if(this.props.voices[x].interviews[0].keywords[myKey] === myKeywords)
                        myInterviews.push(this.props.voices[x]);
                    }
            }
         }
        else// ricerca combinata: 1. cerca match con input value 2. filtra match su picklist
         {
             for(var i in this.props.voices)
             {       
                if(this.state.filteredVoices[i].indexOf(searchValue)> -1)
                    myInterviews.push(this.props.voices[i]);
             }
             //myInterviews ci sono match con #searchValue input
             if(myArtfield!='*' && myKeywords!='*')
             {
                 var firstInterviews = [];
                 for(var y in this.props.voices)
                  for(var myKey2 in  this.props.voices[y].interviews[0].artfields)
                      if(this.props.voices[y].interviews[0].artfields[myKey2] === myArtfield)
                        firstInterviews.push(this.props.voices[y]);
                 
                 myInterviews = [];
                 for(var x in firstInterviews)
                  for(var myKey in  firstInterviews[x].interviews[0].keywords)
                      if(firstInterviews[x].interviews[0].keywords[myKey] === myKeywords)
                        myInterviews.push(firstInterviews[x]);
             }
             else if(myArtfield!='*'){
                 var firstInterviews = myInterviews;
                myInterviews = [];
                 for(var x in firstInterviews)
                  for(var myKey in  firstInterviews[x].interviews[0].artfields)
                      if(firstInterviews[x].interviews[0].artfields[myKey] === myArtfield)
                        myInterviews.push(firstInterviews[x]);
             }
            else if(myKeywords!='*')
            {
                var firstInterviews = myInterviews;
                myInterviews = [];
                for(var x in firstInterviews)
                  for(var myKey in  firstInterviews[x].interviews[0].keywords)
                      if(firstInterviews[x].interviews[0].keywords[myKey] === myKeywords)
                        myInterviews.push(firstInterviews[x]);
            }            
         }
    }  
//    console.log('____________ ', myInterviews);
    this.setState({voices2Display: myInterviews});
}
handleClearFilters(){
    $('#searchValue').val('');
    $('.filters .dropdown select').val("*");
    this.setState({voices2Display: this.props.voices});    
}
    
throttledHandleWindowResize(){
    this.setState({isMobile_w700: window.innerWidth < 700});
      if(window.innerWidth < 700) {
          $('.tableContainer').addClass('mobile_w700'); 
       } 
      else{
           $('.tableContainer').removeClass('mobile_w700');
      }  
}
  render() {
    return (   
        <div className='tableContainer'>
        <div className='filters'>
                <div className='searchContainer'>
                    <span className='searchLabel' >search</span> 
                    <input  id='searchValue' onChange={this.handleSearch} />
                </div>
                <span className='searchLabel toFade' >select</span> 
                 <div className="dropdown artfields">
                    <select onChange={this.handleSearch}> 
                       <option>*</option>
                       {this.props.artfields.map((artfield) => {
                          return (<option key={artfield.id} value={artfield.id}>{artfield.name}</option>);})}
                    </select>
                  </div>
                  <div className="dropdown keywords">
                    <select onChange={this.handleSearch}> 
                       <option>*</option>
                       {this.props.keywords.map((keyword) => {
                          return (<option key={keyword.id} value={keyword.id}>{keyword.name}</option>);})}
                    </select>
                  </div>
                  <a className='searchLabel toFade' href='#clear' onClick={this.handleClearFilters}>x clear filters</a>
            </div>
        <div className='voices-table titles'>
                    <div className="VoicesList-post titles">
                                  <p className="title">voice</p>
                                  <div className='listTitles'>
                                      <p className="interview">interview</p>
                                      <p className='artfield'>art field</p>
                                      <p className='keywords'>keywords</p>
                                     
                                      <p className='year'>year</p>
                                  </div>
                                </div>
        </div>
        <div className='voices-table records'>
                   
                    {this.state.voices2Display.map((voice, index) => {
                              return (
                                <div id={voice.cognome.toLowerCase().charAt(0)} className="VoicesList-post record" key={voice.id} name={index}>
                                  <a className="title" to='#'>{voice.nome} {voice.cognome}
                                  </a>
                                  

{voice.interviews.map((interview, index2) => {
   return (<div className={"listContent " + (index2>0 ? 'addMarginLeft' : '')} key={index2}>
        
            <Link className="interview" to={`/interview/${slugify(interview.title)}`}>                    {interview.title}
            </Link>
            
            <FilterList type='artfield' data={interview.artfields} mapById={this.props.artfields} />

            <FilterList type='keywords' data={interview.keywords} mapById={this.props.keywords} />

                            
              <p className='year'>
                <Moment format="YYYY">
                    {interview.date}
                </Moment>
              </p>
  </div>  );
 })}                              

                                </div>
                              );
                            })}
                </div>
    
</div>
    );
  }//end render
}//end constructor

export default VoiceTable;
import React, { Component }  from 'react'
import { RouteData } from 'react-static'
import { Link } from '@reach/router'
import Moment from 'react-moment';
import FilterList from '../containers/FilterList';
import VoiceTable from '../containers/VoiceTable';
import $ from 'jquery';

class Letters extends Component {
constructor(props) {
    super(props);
    this.state = { 
            letters: []
        };

    this.handleScroll = this.handleScroll.bind(this);
  }
    
componentDidMount() {
    var alphabet = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    
    var myActiveLetters = [];
    this.props.voices.forEach(function(item) {    
        var myLetter = item.cognome.toLowerCase().charAt(0);
        if(myActiveLetters.indexOf(myLetter) == -1)
            myActiveLetters.push(myLetter);          
    });
//     console.log(myActiveLetters);
    
    var myLettersWithLabel = [];
    alphabet.forEach(function(item) {    
//        var myLetter2 = item.cognome.toLowerCase().charAt(0);
        if(myActiveLetters.indexOf(item) == -1)
            myLettersWithLabel.push([item,'disabled']);  
        else
            myLettersWithLabel.push([item,'active']);  
    });
     this.setState({letters: myLettersWithLabel});
    
}
   
handleScroll(letter){
//   console.log('page',this.props.page);
    var voicesByletter;
    if(this.props.page=='voices')
        voicesByletter = $('.voicesContainer .voices-table.records .VoicesList-post.record#'+letter);
    else if(this.props.page=='index')
        voicesByletter = $('.indexContentContainer .voices-list .VoicesList-post#'+letter);
    
//     console.log(voicesByletter);
    if(voicesByletter.length>0){
         var top = voicesByletter.first().position().top;
        if(this.props.page=='voices'){
            top = top - 152;
            $("html, body").animate({ scrollTop: top }, 400);
        }       
        else if(this.props.page=='index'){
            top = top - 91;
             $(".indexContentContainer .voices-list").animate({ scrollTop: top }, 400);
        }
            
//        console.log('scroll', top);
       
        
        $(voicesByletter).css("background-color", "#f8f9fa");
        setTimeout(function() { 
            $(voicesByletter).css("background-color", "#ffffff");
            setTimeout(function() { 
            $(voicesByletter).css("background-color", "#f8f9fa");
                setTimeout(function() { 
                    $(voicesByletter).css("background-color", "#ffffff");
                    setTimeout(function() { 
                    $(voicesByletter).css("background-color", "#f8f9fa");
                        setTimeout(function() { 
                            $(voicesByletter).css("background-color", "#ffffff");
                        }, 150);
                    }, 150);
                }, 150);
            }, 150);
        }, 600);
        
        
//        $(voicesByletter).css("background-color", "#f8f9fa").animate({ backgroundColor: "#FFFFFF"}, 1500);//.css('background-color','#f8f9fa');
    }
}
    
render() {
  return (
                <div className='letters'>
                  
      {this.state.letters.map((letter, index) => {
                      return (
                    
                  <a key={letter[0]} to={letter[1]=='active' ? '#scroll' : '#none'} className={letter[1]} onClick={() => {this.handleScroll(letter[0])}}>{letter[0]}</a>

                      );
            })}
                    
                </div>
 );   
  }// end render
  
}// end component
           
export default Letters;
//{JSON.stringify(interviews)}     
// {JSON.stringify(voices)}     
//{this.mapFilters('artfield', voices.artfields[0])}    

//<a href='#' onClick={() => { this.handleScroll('a') }}>A</a><a href='#' onClick={() => { this.handleScroll('b') }}>B</a><a href='#' onClick={() => { this.handleScroll('c')}}>C</a><a href='#' onClick={() => {this.handleScroll('d')}}>D</a><a href='#' onClick={() => {this.handleScroll('e')}}>E</a><a href='#' onClick={() => {this.handleScroll('f')}}>F</a><a href='#' onClick={() => {this.handleScroll('g')}}>G</a><a href='#' onClick={() => {this.handleScroll('h')}}>H</a><a href='#' onClick={() => {this.handleScroll('i')}}>I</a><a href='#' onClick={() => {this.handleScroll('j')}}>J</a><a href='#' onClick={() => {this.handleScroll('k')}}>K</a><a href='#' onClick={() => {this.handleScroll('l')}}>L</a><a href='#' onClick={() => {this.handleScroll('m')}}>M</a><a href='#' onClick={() => {this.handleScroll('n')}}>N</a><a href='#' onClick={() => {this.handleScroll('o')}}>O</a><a href='#' onClick={() => {this.handleScroll('p')}}>P</a><a href='#' onClick={() => {this.handleScroll('q')}}>Q</a><a href='#' onClick={() => {this.handleScroll('r')}}>R</a><a href='#' onClick={() => {this.handleScroll('s')}}>S</a><a href='#' onClick={() => {this.handleScroll('t')}}>T</a><a href='#' onClick={() => {this.handleScroll('u')}}>U</a><a href='#' onClick={() => {this.handleScroll('v')}}>V</a><a href='#' onClick={() => { this.handleScroll('w')}}>W</a><a href='#' onClick={() => {this.handleScroll('x')}}>X</a><a href='#' onClick={() => {this.handleScroll('y')}}>Y</a><a href='#z' onClick={() => { this.handleScroll('z') }}>Z</a>


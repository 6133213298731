import React, { Component } from 'react';
import { Link } from '@reach/router';
//import $ from 'jquery';

class FilterList extends Component {
  constructor(props) {
        super(props);
        
        this.state = { list: '' };
    } 
    
  componentDidMount() {
//            console.log(this.props.data,this.props.mapById);
            var resultList = '';
            for (var i in this.props.data) {
//                console.log(i, this.props.data[i]);
                for (var j in this.props.mapById) {
                    if(this.props.data[i] === this.props.mapById[j]._id )
                    {
                        if(resultList == '')
                            resultList = this.props.mapById[j].name;
                        else
                            resultList += ', '+this.props.mapById[j].name;
                    }                 
                }
            }
            if(resultList!='')
                this.setState({list: resultList});
  }
    
  render() {
    return (      
        <div className="FilterList" className={this.props.type}>
            <p>
            {this.state.list}
            </p>
        </div>
    );
  }//end render
}//end constructor

export default FilterList;